import { ContentViewModel, ContentView } from "@levelapp/softfabric";
import ProductCategoriesPopUpViewState from "./ProductCategoriesPopUpViewState";
import ProductCategoriesPopUpViewProps from "./ProductCategoriesPopUpViewProps";
import Helpers from "../../../../../../Common/Helpers/Helpers";
import DispatcherManager from "../../../../../../Common/Managers/DispatcherManager";
import TranslationHelper from "../../../../../../Common/Helpers/TranslationHelper";
import ProductCategoriesOperations from "../../../../../../Transfer/ProductCategoriesOperations";
import ToastHelper from "../../../../../../Common/Helpers/ToastHelper";
import ErrorHelper from "../../../../../../Common/Helpers/ErrorHelper";


export default class ProductCategoriesPopUpViewModel extends ContentViewModel<ProductCategoriesPopUpViewState, ProductCategoriesPopUpViewProps>
{
    _dispatcherManager : DispatcherManager;
    _productCategoriesOperation : ProductCategoriesOperations;

    constructor(props: ContentView){
        super(props);

        /* Initial State */

        this.initialState({
            productcategory: this.props().productcategory,
            languages: this.props().languages,
            showErrors: false, 
            externalError: false,
            index: 0, 
            indexThumbnail: 0,
            useDefaultImageChecked: Helpers.hasDuplicates(this.props().productcategory.imageTranslation),
            useDefaultThumbnailChecked: Helpers.hasDuplicates(this.props().productcategory.thumbnailTranslation),
            tab:"main",
            isLoading : false,


        })

        /* BINDINGS */
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleImage = this.handleImage.bind(this);
        this.setIndexImage = this.setIndexImage.bind(this);
        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.setIndexThumbnail = this.setIndexThumbnail.bind(this);
        this.handleThumbnail = this.handleThumbnail.bind(this);
        this.handleDefaultCheckBoxChanged = this.handleDefaultCheckBoxChanged.bind(this);
        this.handleDefaultCheckBoxThumbnailChanged = this.handleDefaultCheckBoxThumbnailChanged.bind(this);
        this.changeTab = this.changeTab.bind(this);  
        this.handleAnonymousChanged =  this.handleAnonymousChanged.bind(this);
        this.handleExternalIdChanged = this.handleExternalIdChanged.bind(this);
        this.handleOrderChanged = this.handleOrderChanged.bind(this);
        this.errorManagement = this.errorManagement.bind(this);
        this.saveProductCategory = this.saveProductCategory.bind(this);
        
        this._dispatcherManager = new DispatcherManager();
        this._productCategoriesOperation = new ProductCategoriesOperations();
    }

    componentDidMount()
    {
        this._dispatcherManager.checkboxAnonymous = this.handleAnonymousChanged;
        this._dispatcherManager.checkboxDefaultImage = this.handleDefaultCheckBoxChanged;
        this._dispatcherManager.checkboxDefaultThumbnail = this.handleDefaultCheckBoxThumbnailChanged;

        this._dispatcherManager.valueOrderIndex = this.handleOrderChanged;
        this._dispatcherManager.valueThirdPartId = this.handleExternalIdChanged;
        
        this._dispatcherManager.translationTitle = this.handleTitleChanged;

        this._dispatcherManager.numberIndexImage = this.setIndexImage;
        this._dispatcherManager.numberIndexThumbnail = this.setIndexThumbnail;

        this._dispatcherManager.uriImage = this.handleImage;
        this._dispatcherManager.uriThumbnail = this.handleThumbnail;
    }
   

     /* COMPONENT EVENTS */
     componentWillMount() { }

     /* OTHERS */

    fillAllThumbnailsWithDefault() {
        if (this.state().useDefaultThumbnailChecked ) 
        {
            var list = Helpers.fillAllImagesWithDefault(this.state().productcategory.thumbnailTranslation,this.state().languages);
            this.setField((previousState) => previousState.productcategory.thumbnailTranslation = list);
        }
    }
    
    fillAllPicturesWithDefault() {
        if (this.state().useDefaultImageChecked ) 
        {
            var list = Helpers.fillAllImagesWithDefault(this.state().productcategory.imageTranslation,this.state().languages);
            this.setField((previousState) => previousState.productcategory.imageTranslation = list);
        }
    }

    isFilledTranslations(){
        for(let i = 0; i<this.state().productcategory.titleTranslations.length ;i++){
            if(this.state().productcategory.titleTranslations[i].value === "" || 
            this.state().productcategory.titleTranslations[i].value === " "){
                return false;
            }
        }
        return true;
    }

    handleSavePressed() {
        if(
            this.state().productcategory.titleTranslations.length != this.state().languages.length ||
            !this.isFilledTranslations()||
            !Helpers.areImagesComplete(this.state().productcategory.imageTranslation) ||
            !Helpers.areImagesComplete(this.state().productcategory.thumbnailTranslation)
        ){
            this.setField((previousState) => previousState.showErrors = true);   
        }
        else{
            this.setField((previousState) => previousState.isLoading = true);
            this.saveProductCategory(this.state().productcategory); 
        }

    }

    saveProductCategory(productcategory: any)
    {
        for(var i = 0; i < this.props().languages.length; ++i){
            if(productcategory.imageTranslation[i].url.includes("data:image"))
                productcategory.imageTranslation[i].ImageData = productcategory.imageTranslation[i].url
              .replace("data:image/png;base64,","")
              .replace("data:image/jpeg;base64,", "")
              .replace("data:image/svg+xml;base64,", "");
            else
                productcategory.imageTranslation[i].ImageData = null;

            if (productcategory.thumbnailTranslation[i].url.includes("data:image"))
                productcategory.thumbnailTranslation[i].ImageData = productcategory.thumbnailTranslation[i].url
              .replace("data:image/png;base64,", "")
              .replace("data:image/jpeg;base64,", "")
              .replace("data:image/svg+xml;base64,", "");
            else
                productcategory.thumbnailTranslation[i].ImageData = null;    
        }


        if (productcategory.id !== undefined) {
            this._productCategoriesOperation.put(productcategory, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("products.categories.popup.success.updated","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }

        else {
            this._productCategoriesOperation.post(productcategory, () => {
                this.props().refreshParent();
                this.showToast(ToastHelper.getToast("products.categories.popup.success.added","success"),() => {},2500);
                this.closePopUp();
            },this.errorManagement);
        }

    }
    
    errorManagement(error : any) 
    {
        this.setField({isLoading : false});
        ErrorHelper.showMessageError(error.response);
    }

    changeTab(tab:any)
    {
        this.setField((previousState) => previousState.tab = tab);
    }

    

    /* INDEX IMAGE */

    private setIndexImage(index: any) {
        this.setField((previousState) => previousState.index = index);
    }

    private setIndexThumbnail(index: any) {
        this.setField((previousState) => previousState.indexThumbnail = index);
    }

    /* URI IMAGE */

    private handleImage(base64 : any) {
        var state = this.state();
        TranslationHelper.image(state.productcategory.imageTranslation,state.languages,state.index,base64);
        this.setField({state});
        this.fillAllPicturesWithDefault();
    }

    private handleThumbnail(base64 : any)
    {
        var state = this.state();
        TranslationHelper.image(state.productcategory.thumbnailTranslation,state.languages,state.indexThumbnail,base64);
        this.setField({state});
        this.fillAllThumbnailsWithDefault();
    } 

    /* CHECKBOX */

    private handleDefaultCheckBoxChanged(value : boolean) {
        this.setField((previousState) => previousState.useDefaultImageChecked = value);
        this.fillAllPicturesWithDefault();
    }

    private handleDefaultCheckBoxThumbnailChanged(value : boolean) {
        this.setField((previousState) => previousState.useDefaultThumbnailChecked = value);
        this.fillAllThumbnailsWithDefault();
    }

    /* TRANSLATIONS */

    private handleTitleChanged(text: any, index: any) {
        var state = this.state();
        TranslationHelper.translation(state.productcategory.titleTranslations,state.languages,index,text);
        this.setField({state});
    }

    /* VALUES */
  
    private handleExternalIdChanged(proctgExterns: any){
        this.setField((previousState) => previousState.externalError = false);
        if(proctgExterns != undefined){
            proctgExterns.forEach((element: any) => {
                if(isNaN(element.externalId)){
                    this.setField((previousState) => previousState.externalError = true);
                }
                else{
                    let test = Number(element.externalId);
                    if(!Number.isInteger(test)){
                        this.setField((previousState) => previousState.externalError = true);
                    }
                }
            });
            let protcg = this.state().productcategory;
            protcg.appProductCategoryExterns = proctgExterns;
             this.setField({productcategory : protcg});
        }
       
    }

    private handleOrderChanged(text : string)
    {
        var productcategory = this.state().productcategory;
        productcategory.orderIndex = text;
        this.setField({productcategory})
    }

    /* CHECKBOX */

    private handleAnonymousChanged(value : boolean) {
        this.setField((previousState) => previousState.productcategory.isAnonymousVisible = value);
    }

    

}